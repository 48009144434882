// ==========================================================================
// Base
// ==========================================================================

/* global $ */

"use strict";


//Prevent # anchors jumping page to top
$('a[href="#"]').click(function (e) {
    e.preventDefault();
});


/*
if (typeof DEVELOPMENT !== "undefined" && DEVELOPMENT) {
    $("body").addClass("debug");
}
*/
if ( $( "#gallery" ).length ) {

    $(function () {

        var $container = $('#gallery');
        // hide initial items
        var $initialItems = $container.find('.item').hide();

        var $container = $container.masonry({
            // do not select initial items
            columnWidth: '.grid-sizer',
            itemSelector: '.item',
            gutter: '.gutter-sizer'
        })
          // set option
          .masonry('option', {itemSelector: '.item'})
          .masonryImagesReveal($initialItems);
    });

    // reveals items iteratively
    // after each item has loaded its images
    $.fn.masonryImagesReveal = function ($items) {
        var msnry = this.data('masonry');
        var itemSelector = msnry.options.itemSelector;
        // hide by default
        $items.hide();
        // append to container
        this.append($items);
        $items.imagesLoaded().progress(function (imgLoad, image) {
            // get item
            // image is imagesLoaded class, not <img>, <img> is image.img
            var $item = $(image.img).parents(itemSelector);
            // un-hide item
            $item.show();
            // masonry does its thing
            msnry.appended($item);
        });

        return this;
    };


}
$(document).ready(function () {
    $('.gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title') + '<small>Ian McGregor Architects</small>';
            }
        }
    });
});

$(document).ready(function () {
    $('.customer-logos').slick({
        slidesToShow: 4,
        autoplay: false,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 3
            }
        }]
    });
});


$(function () {
    var count = 0;
    var wordsArray = ["home", "office", "shop", "space"];
    setInterval(function () {
        count++;
        $(".HeroBlock__headline span").fadeOut("slow", function () {
            $(this).text(wordsArray[count % wordsArray.length]).fadeIn("slow");
        });
    }, 4000);
});


    $('.timeline.slick').slick({
        slidesToShow: 7,
        slidesToScroll: 7,
        dots: false,
        arrows: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }, 
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            }
        ]
    });
